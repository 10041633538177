//jquery
const jquery = require('jquery');
$ = window.$ = window.jQuery = jquery;
// slick
const slick = require('slick-carousel');

//mobile nav
$(() => {
  const $toggle = $('#mobile-toggle');
  const $mobileNav = $('#mobile-nav');

  $toggle.on('click', function(e) {
    e.preventDefault();
    $(this).toggleClass('active');
    $mobileNav.toggleClass('active');
  });
});

$(() => {
  const $toggle = $('#mobile-toggle');
  const $mobileNav = $('#mobile-nav');
  const $breakpoint = 992;

  $(window).on('load resize', function () {
    let $windowWidth = $(window).width();
    if ($windowWidth >= $breakpoint) {
      $toggle.removeClass('active');
      $mobileNav.removeClass('active');
    }
  });
});

//slider
$(() => {
  const $slider = $('.customer-stories');

  if ($slider.length > 0) {
    $slider.slick({
      arrows: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
    });
    $('#story-prev').click(function(e) {
      e.preventDefault();
      $slider.slick('slickPrev');
    });
    $('#story-next').click(function(e) {
      e.preventDefault();
      $slider.slick('slickNext');
    });
  }
});

//faqs
$(() => {
  const $faq = $('.faqs-card');
  
  $faq.on('click', function(e) {
    const $this = $(this);
    const $body = $this.find('.faqs-body');
    $this.toggleClass('active');
    $body.slideToggle(300);
  });
});